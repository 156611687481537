<template>
  <b-card>
    <b-form>
      <validation-observer ref="wifiSettingsModal">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-overlay
                :show="isLoading"
                rounded
                opacity="0.5"
                spinner-small
                spinner-variant="primary"
              >
                <div
                  class="section-block mb-4"
                >
                  <div class="section-block__title">
                    <feather-icon
                      icon="MonitorIcon"
                      width="24"
                      height="24"
                    />
                    <h2>Update WiFi Network</h2>
                  </div>
                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Network Name:"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Network Name:"
                        vid="lan_id"
                        rules="max:255"
                      >
                        <v-select
                          v-model="network"
                          :clearable="false"
                          label="name"
                          placeholder="Select network"
                          :options="networks"
                          is-open
                          open
                          return-object
                          filled
                          :searchable="true"
                          disabled
                          @search="onSearchNetwork"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="SSID:"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="SSID:"
                        vid="ssid"
                        rules="required|max:255"
                      >
                        <b-form-input
                          v-model="form.ssid"
                          :state="errors.length > 0 ? false:null"
                          placeholder="SSID:"
                          autocomplete="off"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Authentication Type:"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Authentication Type"
                        vid="authentication_type"
                        rules="required"
                      >
                        <v-select
                          v-model="form.authentication_type"
                          :options="authList2G"
                          :reduce="authentication_type => authentication_type.id"
                          :searchable="false"
                          label="name"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    v-if="form.authentication_type !== 'none' || !form.authentication_type"
                    class="neighbouring-form-container"
                  >
                    <b-form-group
                      label="Password:"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Password:"
                        vid="password"
                        rules="max:255"
                      >

                        <b-input-group
                          class="input-group-merge"
                        >
                          <b-form-input
                            id="register-password"
                            v-model="form.password"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            :state="errors.length > 0 ? false:null"
                            name="register-password"
                            placeholder="Password:"
                            autocomplete="off"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon()"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    class="neighbouring-form-container mt-2 switch-row-container"
                  >
                    <b-form-group
                      label=""
                      label-class="label-class"
                      class="neighbouring-form-group switch-row"
                    >
                      <p>SSID Visible:</p>
                      <validation-provider
                        #default="{ errors }"
                        name="SSID Visible:"
                        vid="is_visible"
                      >
                        <b-form-checkbox
                          v-model="form.is_visible"
                          name="is_visible"
                          switch
                          inline
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    class="neighbouring-form-container mt-2 switch-row-container"
                  >
                    <b-form-group
                      label=""
                      label-class="label-class"
                      class="neighbouring-form-group switch-row"
                    >
                      <p>Is 5G same as 2G:</p>
                      <validation-provider
                        #default="{ errors }"
                        name="Is 5G same as 2G"
                        vid="is5GSame"
                      >
                        <b-form-checkbox
                          v-model="is5GSame"
                          name="is_same"
                          switch
                          inline
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    v-if="!is5GSame"
                    class="section-block mb-4"
                  >

                    <div class="section-block__title">
                      <feather-icon
                        icon="MonitorIcon"
                        width="24"
                        height="24"
                      />
                      <h2>5G WiFi Network</h2>
                    </div>
                    <div
                      class="neighbouring-form-container"
                    >
                      <b-form-group
                        label="SSID:"
                        label-class="label-class"
                        class="neighbouring-form-group"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="SSID:"
                          vid="ssid"
                          rules="required|max:255"
                        >
                          <b-form-input
                            v-model="form.g5.ssid"
                            :state="errors.length > 0 ? false:null"
                            placeholder="SSID:"
                            autocomplete="off"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div
                      class="neighbouring-form-container"
                    >
                      <b-form-group
                        label="Authentication Type:"
                        label-class="label-class"
                        class="neighbouring-form-group"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Authentication Type"
                          vid="authentication_type"
                          rules="required"
                        >
                          <v-select
                            v-model="form.g5.authentication_type"
                            :options="authList5G"
                            :reduce="authentication_type => authentication_type.id"
                            :searchable="false"
                            label="name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <h5
                      v-if="form.g5.authentication_type === 'wpa2-psk,wpa3-psk' || form.g5.authentication_type === 'wpa3-psk'"
                      style="
                      color: #F4B32A;
                      margin-bottom: 20px;
                    "
                    >
                      Warning: Using WPA3 in a mixed 802.11ac and 802.11ax environment may cause issues with roaming between radio types. To ensure seamless roaming across different radios, we recommend using WPA2 throughout your network.
                    </h5>
                    <div
                      v-if="form.g5.authentication_type !== 'none'"
                      class="neighbouring-form-container"
                    >
                      <b-form-group
                        label="Password:"
                        label-class="label-class"
                        class="neighbouring-form-group"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Password:"
                          vid="password"
                          rules="max:255"
                        >
                          <b-input-group
                            class="input-group-merge"
                          >
                            <b-form-input
                              v-model="form.g5.password"
                              :state="errors.length > 0 ? false:null"
                              placeholder="Password:"
                              autocomplete="off"
                              :type="passwordFieldType5G"
                            />
                            <b-input-group-append is-text>
                              <feather-icon
                                class="cursor-pointer"
                                :icon="passwordToggleIcon5G()"
                                @click="togglePasswordVisibility5G"
                              />
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                    <div
                      class="neighbouring-form-container mt-2 switch-row-container"
                    >
                      <b-form-group
                        label=""
                        label-class="label-class"
                        class="neighbouring-form-group switch-row"
                      >
                        <p>SSID Visible:</p>
                        <validation-provider
                          #default="{ errors }"
                          name="SSID Visible:"
                          vid="is_visible"
                        >
                          <b-form-checkbox
                            v-model="form.g5.is_visible"
                            name="is_visible"
                            switch
                            inline
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                  <p class="mb-0 mt-3">
                    Encryption:
                  </p>
                  <div
                    class="neighbouring-form-container mt-2 switch-row-container"
                  >
                    <b-form-group
                      label=""
                      label-class="label-class"
                      class="neighbouring-form-group switch-row"
                    >
                      <p>AES CCM:</p>
                      <validation-provider
                        #default="{ errors }"
                        name="AES CCM:"
                        vid="aes_ccm"
                      >
                        <b-form-checkbox
                          v-model="form.aes_ccm"
                          name="aes_ccm"
                          switch
                          inline
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label=""
                      label-class="label-class"
                      class="neighbouring-form-group switch-row"
                    >
                      <p>TKIP:</p>
                      <validation-provider
                        #default="{ errors }"
                        name="TKIP"
                        vid="tkip"
                      >
                        <b-form-checkbox
                          v-model="form.tkip"
                          name="tkip"
                          switch
                          inline
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    class="neighbouring-form-container mt-2 switch-row-container"
                  >
                    <b-form-group
                      label=""
                      label-class="label-class"
                      class="neighbouring-form-group switch-row"
                    >
                      <p>Limit bridge horizon:</p>
                      <validation-provider
                        #default="{ errors }"
                        name="SSID Visible:"
                        vid="is_limit_bridge"
                      >
                        <b-form-checkbox
                          v-model="form.is_limit_bridge"
                          name="is_limit_bridge"
                          switch
                          inline
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label=""
                      label-class="label-class"
                      class="neighbouring-form-group switch-row"
                    >
                      <p>Client-to-client forwarding:</p>
                      <validation-provider
                        #default="{ errors }"
                        name="SSID Visible:"
                        vid="client_forwarding"
                      >
                        <b-form-checkbox
                          v-model="form.client_forwarding"
                          name="client_forwarding"
                          switch
                          inline
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <div
                    class="neighbouring-form-container mt-2 switch-row-container"
                  >
                    <b-form-group
                      label="Country"
                      label-for="country"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Country"
                        vid="country"
                        rules="max:255"
                      >

                        <v-select
                          v-model="form.country"
                          :options="countries"
                          :reduce="country => country.name"
                          placeholder="Select country"
                          label="name"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                    <b-form-group
                      label="Installation"
                      label-for="installation"
                      label-class="label-class"
                      class="neighbouring-form-group"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Installation"
                        vid="installation"
                        rules="max:255"
                      >

                        <v-select
                          v-model="form.installation"
                          :options="installations"
                          :reduce="installation => installation.id"
                          placeholder="Select installation"
                          label="name"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </div>
              </b-overlay>
              <!-- submit button -->
              <b-col
                cols="12"
                class="text-right buttons-container__left"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  ok-variant="secondary"
                  @click.prevent="goBack"
                >
                  Cancel
                </b-button>
                <b-button-request
                  :disabled="disabledSaveButton"
                  text="Update"
                  :request="updateItemHandle"
                />
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-form>
  </b-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import {
  BCard, BButton, BRow, BCol, BFormInput, BFormGroup, BForm, BFormCheckbox, BOverlay, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, max } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import axios from '../../../axios-resolver'
import BButtonRequest from '../../../../../components/BButtonRequest.vue'

export default {
  components: {
    BButtonRequest,
    BButton,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormGroup,
    BForm,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      wifiId: this.$route.params.wifiId,
      id: this.$route.params.wifiId,
      siteId: this.$route.params.id,
      required,
      isLoading: true,
      max,
      form: {
        lan_id: '',
        ssid: '',
        authentication_type: '',
        password: '',
        is_visible: false,
        is_limit_bridge: false,
        client_forwarding: false,
        aes_ccm: false,
        country: '',
        tkip: false,
        g5: {
          ssid: '',
          authentication_type: '',
          password: '',
          is_visible: false,
        },
      },
      is5GSame: true,
      disabledSaveButton: false,

      network: null,
      networks: [],
      searchNetwork: '',
      passwordFieldType: 'password',
      passwordFieldType5G: 'password',
      authList2G: [],
      authList5G: [],
      countries: [],
      installations: [],
    }
  },
  destroyed() {
    document.querySelector('.content-header-title__extra-info').innerHTML = ''
  },
  computed: {
    ...mapState('wifi', {
      encryptionList: 'encryptionList',
    }),
  },
  async created() {
    this.setSiteName(this.siteId)
    await this.getNetworks()
    await this.getEncryptionList()
    this.getWifiInfo()
  },
  methods: {
    ...mapActions('wifi', [
      'updateItem',
      'createItem',
    ]),
    async setSiteName(id) {
      try {
        if (id) {
          const pageTitle = document.querySelector('.content-header-title__extra-info')
          const { data: { data } } = await axios.get(`/site/${id}/show`)

          const link = document.createElement('a')
          link.href = `/site/${id}/hardware/index`
          link.innerHTML = `(${data.name})`

          pageTitle.innerHTML = ''
          pageTitle.appendChild(link)
        }

        this.isAccessPointUnlicensed = this.summary.isAccessPointUnlicensed // do when the API will be ready
      } catch (error) {
        console.log('error', error)
      }
    },
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    togglePasswordVisibility5G() {
      this.passwordFieldType5G = this.passwordFieldType5G === 'password' ? 'text' : 'password'
    },
    passwordToggleIcon5G() {
      return this.passwordFieldType5G === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    async getEncryptionList() {
      try {
        const { data } = await axios.get(`/site/${this.siteId}/wifi/setting/index`)

        this.authList2G = data.options.wifiAuthenticationTypes2G.data
        this.authList5G = data.options.wifiAuthenticationTypes5G.data
        this.countries = data.options.countries.data
        this.installations = data.options.wifiInstallations.data
      } catch (error) {
        console.error(error)
      }
    },
    async getWifiInfo() {
      try {
        const { data: { data } } = await axios.get(`/site/${this.siteId}/wifi/setting/${this.wifiId}`)

        const installation = this.installations.find(el => el.id === data.installation)
        const country = this.countries.find(el => el.name.toLowerCase() === data.country)

        this.form = {
          lan_id: data.lan_id,
          ssid: data.ssid,
          encryption: data.encryption,
          password: data.password,
          is_visible: data.is_visible,
          is_limit_bridge: data.is_limit_bridge,
          client_forwarding: data.client_forwarding,
          g5: data.g5,
          country: country ? country.name : '',
          installation: installation ? installation.id : '',
          aes_ccm: data.aes_ccm,
          tkip: data.tkip,
          authentication_type: data.authentication_type,
        }

        this.is5GSame = data.same_as_2g

        this.network = this.networks.find(el => el.id === data.lan_id)
      } catch (error) {
        console.warn(error)
        this.goBack()
      } finally {
        this.isLoading = false
      }
    },
    async getNetworks() {
      try {
        const { data } = await axios.get(`/site/${this.siteId}/network/setting/lan/index?page=1${this.searchNetwork ? `&search=${this.searchNetwork}` : ''}`)

        if (Array.isArray(data.data)) {
          this.networks = data.data
        } else {
          this.networks = []
        }
      } catch (error) {
        console.warn('getNetworks error', error)
      }
    },
    async updateItemHandle() {
      const valid = await this.$refs.wifiSettingsModal.validate()
      if (valid) {
        try {
          this.disabledSaveButton = true
          const payload = {
            ...this.form,
            lan_id: this.network.id,
            is_visible: this.form.is_visible,
            id: this.wifiId,
          }
          if (this.is5GSame) {
            delete payload.g5
          }
          if (this.form.g5.authentication_type === 'none') {
            delete payload.g5.password
          }
          if (this.form.authentication_type === 'none') {
            delete payload.password
          }
          await this.updateItem({ siteId: this.siteId, payload })
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          this.goBack()
        } catch (error) {
          console.warn('error', error)
          const { status } = error.response

          if (!status) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Server error',
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          }

          const { message } = error.response.data

          this.$toast({
            component: ToastificationContent,
            props: {
              title: message,
              icon: 'BellIcon',
              variant: 'danger',
              message,
            },
          })
        } finally {
          this.disabledSaveButton = false
        }
      }
    },
    onSearchNetwork(search) {
      this.searchNetwork = search
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
